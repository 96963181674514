<template>
   <v-container grid-list-md style="max-width:2000px;">
      <v-layout
         text-center
         justify-center
         align-center
         wrap
      >
         <v-flex xs12 md12>
            <div>

               <v-card flat tile
                  style="background-color:transparent;"
                  class="px-0">
                  <v-card-title class="py-1 px-0 pl-4"
                     style="color:teal;border-bottom:1px solid teal;line-height:1.2;">
                     <b>Import Parts Data</b>
                     <v-spacer/>
                     <v-btn
                        color="teal"
                        light
                        style="color:white;"
                        class="text-none px-3"
                        @click="startPickAFile"
                        >
                        <v-icon small class="mr-2">attach_file</v-icon>
                        Select File
                     </v-btn>
                     <input
                        type="file"
                        style="display: none"
                        ref="fileInput"
                        accept="*/*"
                        @change="processSelectedFile"
                     >
                     <v-btn
                        color="teal"
                        light
                        style="color:white;"
                        :disabled="selectedParts.length==0"
                        class="text-none px-3 ml-2"
                        @click="saveToSystem"
                        >
                        <v-icon small class="mr-2">save</v-icon>
                        Save To System
                     </v-btn>
                  </v-card-title>
               </v-card>

               <div style="text-align:left;" 
                  class="mt-2">

                  <div class="mt-1">
                     <v-data-table
                        v-model="selectedParts"
                        :headers="partHeaders"
                        :items="parts"
                        :single-select="false"
                        item-key="index"
                        show-select dense
                        class="elevation-1"
                        :page.sync="page"
                        :items-per-page="itemsPerPage"
                        hide-default-footer
                        @page-count="pageCount = $event"
                     >
                     <!--
                        <template v-slot:item.sitenames="{ item }">
                          {{item.sitenames.map(s=>s.name).join(', ')}}
                        </template>
                        -->
                     </v-data-table>
                     <div v-if="pageCount>1"
                        class="text-center pt-2">
                        <v-pagination
                        v-model="page"
                        :length="pageCount"
                        ></v-pagination>
                     </div>
                  </div>

               </div>
               <v-divider/>
            </div>
         </v-flex>
      </v-layout>
   </v-container>
</template>

<script>
let XLSX = require("xlsx")
export default {
   data () {
      return {
         parts: [],
         selectedParts: [],
         page: 1,
         pageCount: 0,
         itemsPerPage: 15,
         partHeaders: [
            {
               text: 'STT',
               align: 'start',
               sortable: true,
               value: 'index',
            },
            {
               text: 'Nhóm TS LV3',
               align: 'start',
               sortable: true,
               value: 'categoryLv3',
            },
            {
               text: 'Nhóm TS LV4',
               align: 'start',
               sortable: true,
               value: 'categoryLv4',
            },
            {
               text: 'Tên TS',
               align: 'start',
               sortable: true,
               value: 'name',
            },
            {
               text: 'Đơn vị',
               align: 'start',
               sortable: true,
               value: 'unit',
            },
            {
               text: 'Số Lượng',
               align: 'start',
               sortable: true,
               value: 'qty',
            },
            {
               text: 'Serial',
               align: 'start',
               sortable: true,
               value: 'serial',
            },
            {
               text: 'Model',
               align: 'start',
               sortable: true,
               value: 'model',
            },
            {
               text: 'Trạng thái',
               align: 'start',
               sortable: true,
               value: 'status',
            },
            {
               text: 'Đơn vị QL',
               align: 'start',
               sortable: true,
               value: 'owner',
            },
            {
               text: 'Mã kho',
               align: 'start',
               sortable: true,
               value: 'makho',
            },
            {
               text: 'Mô tả',
               align: 'start',
               sortable: false,
               value: 'description',
            },
         ],
      }
   },
   methods: {
      startPickAFile: function () {
         this.$refs.fileInput.click();
      },
      processSelectedFile: function() {
         var self = this
         let file = this.$refs.fileInput.files ? this.$refs.fileInput.files[0] : null;
         // let workbook = XLSX.readFile(this.file);
         if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
               /* Parse data */
               const bstr = e.target.result;
               const wb = XLSX.read(bstr, { type: 'binary' });
               /* Get first worksheet */
               const wsname = wb.SheetNames[0];
               const ws = wb.Sheets[wsname];
               /* Convert array of arrays */
               const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
               self.processExcelData(data)
            }

            reader.readAsBinaryString(file);
         }
      },
      processExcelData (data) {
         console.log("Excel data")
         // console.log(data)
         this.parts = []
         let index = 0
         data.forEach((row, idx) => {
            if(row.length>0 && idx>0){
               index++
               this.parts.push({
                  index: index,
                  categoryLv3: row.length>1?row[1]:'',
                  categoryLv4: row.length>2?row[2]:'',
                  name: row.length>3?row[3]:'',
                  unit: row.length>4?row[4]:'',
                  qty: row.length>5?row[5]:'',
                  serial: row.length>6?row[6]:'',
                  model: row.length>7?row[7]:'',
                  status: row.length>8?row[8]:'',
                  owner: row.length>9?row[9]:'',
                  makho: row.length>10?row[10]:'',
                  description: row.length>11?row[11]:'',
               })
            }
         })
      },
      createPropertyCode (name, timeInLong) {
         return name.normalize("NFD")
                                    .replace(/[\u0300-\u036f]/g, "")
                                    .replace(/đ/g, "d")
                                    .replace(/Đ/g, "D")
                                    .replace(/\s+/g, '-')
                                    .toUpperCase() + "-"+ timeInLong
      },
      saveToSystem () {
         // alert('For tetsting purpose, data not save!')
         // console.log(this.selectedParts)
         let timeInLong = new Date().getTime()
         let saveProperties = []
         this.selectedParts.forEach((part, index) => {
            let property = {
               type: 'thiet-bi',
               name: part.name || '',
               images: [],
               qr_code: '',
               code: this.createPropertyCode(part.name, timeInLong + index),
               owner: part.owner || '',
               description: part.description || '',
               status: part.status || '',
               props: {
                  product_brandname: part.model || '',
                  product_serial: part.serial || '',
                  categoryLv3: part.categoryLv3 || '',
                  categoryLv4: part.categoryLv4 || '',
                  unit: part.unit || '',
                  qty: part.qty || '',
                  makho: part.makho || ''
               }
            }
            saveProperties.push(property)
         })
         let self = this
         let numOfItem = saveProperties.length
         saveProperties.forEach((prop, iProp) => {
            setTimeout(function() {
               console.log(iProp+"/"+numOfItem)
               self.callCreateProperty(prop)
            }, 2000*iProp)
         })
         // console.log(saveProperties)
      },
      callCreateProperty (property) {
         let self = this
         this.axios .post(this.$root.apiAssetMana + "/properties",
               property,
               { headers: { "x-auth": self.token } })
         .then((res) => {
            if (res.data.status === "OK") {
               console.log("Tạo thiết bị " + property.name + " thành công!")
            } else {
               console.log("Lỗi: " + res.data.message);
            }
         })
         .catch((err) => {
            console.log("Lỗi: " + err.message);
         })
      },
   }
}
</script>